import $ from 'jquery';

export default class SlickSlider {
  constructor() {
    this.zoomInBtn = document.querySelector('.js-zoom-in');

    this.init();

    if (this.zoomInBtn) {
      this.handleZoom();
    }
  }
  init() {
    const chevronUrl = `${prestashop.urls.img_url}static/svg-defs.svg#chevron`;

    const configDefault = {
      prevArrow: `<button aria-label="Slide précédente" type="button" class="btn btn-link slick-prev slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      nextArrow: `<button aria-label="Slide suivante" type="button" class="btn btn-link slick-next slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      customPaging: () => {
        return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
      },
      verticalSwiping: true,
    }

    const configFeaturedProducts = {
       customPaging: () => {
        return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
      },
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      arrows: false,
      dots: true,
      rows: 0,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          }
        }
      ]
    }

    const configFeaturedCategories = {
      customPaging: () => {
        return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
      },
      slidesToShow: 2,
      slidesToScroll: 1,
      mobileFirst: true,
      arrows: false,
      dots: true,
      rows: 2,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          }
        }
      ]
    }

    const configProductCover = {
      prevArrow: `<button aria-label="Slide précédente" type="button" class="btn btn-link slick-prev slick-arrow"><span><svg width="6" height="10" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      nextArrow: `<button aria-label="Slide suivante" type="button" class="btn btn-link slick-next slick-arrow"><span><svg width="6" height="10" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      customPaging: () => {
        return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
      },
      asNavFor: '[data-slick].product-thumbs',
      rows: 0,
      slidesToShow: 1,
      arrows: false,
      touchMove: true,
      fade: true,
      cssEase: 'linear',
      dots: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
          }
        }
      ]
    }

    const configProductThumb = {
      asNavFor: '[data-slick].products-imagescover',
      slidesToShow: 5,
      slidesToScroll: 1,
      focusOnSelect: true,
      vertical: true,
      verticalSwiping: true,
      adaptiveHeight: true,
      infinite: true,
      dots: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            vertical: true,
          }
        },
      ]
    };

    $('[data-slick]').not('.slick-initialized').each(function() {
      let self = $(this);

      if(self.data('count') === 1){
        return;
      }

      if (self.data('slick-config') === 'configFeaturedProducts') {
        self.slick(configFeaturedProducts);
      } else if (self.data('slick-config') === 'configFeaturedCategories') {
        self.slick(configFeaturedCategories);
      } else if (self.data('slick-config') === 'configProductCover') {
        self.slick(configProductCover);
      } else if (self.data('slick-config') === 'configProductThumb') {
        self.slick(configProductThumb);
      } else if (self.data('slick-config') === 'configModal') {

        return;
      } else {
        self.slick(configDefault);
      }

      self.addClass('displayed');
    });
  }

  handleZoom() {
    // open modal named #product-modal
    this.zoomInBtn.addEventListener('click', (e) => {
      const modal = $('#product-modal');
      modal.modal();

      const slickthumb = $('[data-slick-config="configProductThumb"]');
      const index = slickthumb.slick('slickCurrentSlide');

      // init slick slider in modal
      $('[data-slick]').each(function() {
        const self = $(this);
        if (self.data('slick-config') === 'configModal') {
          // slider is init. Just console.log something
          if (self.hasClass('slick-initialized')) {
            self.slick('slickGoTo', index || 0);
          } else {
            // slider is not init. Init it
            const chevronUrl = `${prestashop.urls.img_url}static/svg-defs.svg#chevron`;
            const slick = self.slick({
              prevArrow: `<button aria-label="Slide précédente" type="button" class="btn btn-link slick-prev slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
              nextArrow: `<button aria-label="Slide suivante" type="button" class="btn btn-link slick-next slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
              customPaging: () => {
                return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
              },
              verticalSwiping: true,
            });

            // goto slide 0
            slick.slick('slickGoTo', index || 0);
            self.addClass('displayed');
          }
        }
      });
    });
  }
}
