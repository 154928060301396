export class PriceSwitch {
  constructor() {
    this.toggle = document.querySelector('.js-price-switch');
    this.form = this.toggle ? this.toggle.closest('form') : null;

    if (this.toggle && this.form) {
      this.init();
    }
  }

  init() {
      this.toggle.addEventListener('click', () => {
        this.form.submit();
      });
  }
}
