export class Header {
  constructor() {
    this.header = document.querySelector('.js-header');

    if (this.header) {
      this.init();
    }
  }

  init() {
    let scrollPos = 0;
    let minScroll = 100;

    window.addEventListener('scroll', () => {
      if ((document.body.getBoundingClientRect()).top > scrollPos && (document.body.getBoundingClientRect()).top < minScroll) {
        this.header.classList.add('is-stuck');
      } else {
        this.header.classList.remove('is-stuck');
      }

      scrollPos = (document.body.getBoundingClientRect()).top;
    });
  }
}
