import Plyr from 'plyr';

export class Youtube {
  constructor() {
    this.videoWrappers = [...document.querySelectorAll('.js-youtube-wrapper')];
    this.vendor = 'youtube';
    this.playerWidth = 960;
    this.playerHeight = 540;

    if (this.videoWrappers.length) {
      this.waitForAxeptio();
    }
  }

  /**
   * Wait for Axeptio to be loaded
   *
   * @returns {void}
   * @memberof VideoBlock
   */
  waitForAxeptio() {
    const wait = (resolve, reject) => {
      const timeout = 5000;
      const start = Date.now();

      if (typeof _axcb !== 'undefined') {
        resolve();
      } else if (timeout && Date.now() - start >= timeout) {
        reject(new Error('Timeout'));
      } else {
        setTimeout(wait.bind(this, resolve, reject), 50);
      }
    };

    const promise = new Promise(wait);

    promise
      .then(() => {
        this.initContextualConsent();
      })
      .catch((error) => {
        console.log(`Not able to load Axeptio: ${error}`);
      });
  }

  initContextualConsent() {
    // Contextual consent https://developers.axeptio.eu/v/francais/integration/consentement-contextuel
    // -- Youtube -- Vimeo -- //
    const warnings = document.querySelectorAll('.js-youtube-warning');

    (_axcb = window._axcb || []).push((sdk) => {
      sdk.on('cookies:complete', (choices) => {
        warnings.forEach(warning => {
          if (choices[this.vendor]) {
            warning.classList.add('d-none');
            this.setupPlyr();
          } else {
            warning.classList.remove('d-none');

            if (this.player) {
              this.player.destroy();
            }
          }
        });
      });
    });
  }

  /**
   * Setup Plyr for all video blocks
   *
   * @returns {void}
   * @memberof Youtube
   */
  setupPlyr() {
    this.videoWrappers.forEach((wrapper) => {
      const playerContainer = wrapper.querySelector('.js-youtube-container');

      // Set width and height for the player container
      playerContainer.style.maxWidth = `${this.playerWidth}px`;
      playerContainer.style.maxHeight = `${this.playerHeight}px`;

      this.initPlyr(wrapper);
    });
  }

  /**
   * Initialize Plyr for a video block
   *
   * @param {HTMLElement} wrapper
   * @returns
   * @memberof Youtube
   */
  initPlyr(wrapper) {
    const video = wrapper.querySelector('.js-youtube');

    if (!video) return;

    video.style.display = 'block';

    this.player = new Plyr(video, {
      volume: 0.5,
      youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
      },
    });
  }
}
