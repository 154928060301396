export class CheckoutTabs {
  constructor() {
    this.triggers = document.querySelectorAll('.js-checkout-tabs-trigger');
    this.navLinks = document.querySelectorAll('.js-checkout-tabs-navlink');

    if (this.triggers.length && this.navLinks.length) {
      this.init();
    }
  }

  init() {
    this.triggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        const {anchor} = trigger.dataset;

        this.navLinks.forEach((navLink) => {
          if (navLink.href.includes(anchor)) {
            navLink.click();
          }
        });
      });
    });
  }
}
