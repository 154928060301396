export class Contact {
  constructor() {
    this.contactForm = document.querySelector('.js-contact-form');
    this.urlString = (window.location.href).toLowerCase();

    if (this.contactForm) {
      this.init();
    }
  }

  init() {
    const subjectField = this.contactForm.querySelector('.js-subject-field');
    const url = new URL(this.urlString);
    const subjectId = parseInt(url.searchParams.get('subject'), 10);

    if (subjectField) {
      const options = [...subjectField.querySelectorAll('option')];

      options.forEach((option, index) => {
        if (index + 1 === subjectId) {
          subjectField.value = option.value;
        }
      });
    }
  }
}
