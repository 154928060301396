export class ActiveCampaign {
  constructor() {
    this.nlFormWrapper = document.querySelector('.js-active-campaign-form');
    this.nlFormTrigger = document.querySelector('.js-active-campaign-form-trigger');

    if (this.nlFormWrapper && this.nlFormTrigger) {
      this.init();
    }
  }

  init() {
    this.nlFormTrigger.addEventListener('click', () => {
      if (this.script) {
        this.script.remove();
      }

      this.createNewScriptTag()
    });
  }

  createNewScriptTag() {
    this.script = document.createElement('script');
    this.script.src = this.nlFormWrapper.dataset.scriptSrc;

    document.head.appendChild(this.script);
  }
}
