export class Matomo {
  constructor() {
    const matomoServerElem = document.querySelector('meta[property=\'matomo-server\']');
    const matomoSiteIdElem = document.querySelector('meta[property=\'matomo-site-id\']');

    if (!matomoServerElem || !matomoSiteIdElem) {
      return;
    }

    const matomoServer = matomoServerElem.getAttribute('content');
    const matomoSiteId = matomoSiteIdElem.getAttribute('content');

    const _paq = window._paq = window._paq || [];
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    _paq.push(['setTrackerUrl', matomoServer+'matomo.php']);
    _paq.push(['setSiteId', matomoSiteId]);

    const scriptElem = document.createElement('script');
    scriptElem.async = true;
    scriptElem.src = matomoServer+'matomo.js';

    const scriptNode = document.getElementsByTagName('script')[0];
    scriptNode.parentNode.insertBefore(scriptElem, scriptNode);
  }
}
