export class Cards {
  constructor() {
    this.cards = [...document.querySelectorAll('.js-card')];

    if (this.cards.length) {
      this.liftOff();
    }

  }

  /**
   * Fire up everything
   *
   * @memberof Cards
   */
  liftOff() {
    this.cards.forEach((card) => {
      const mainLink = card.querySelector('.js-main-link');
      const clickableElements = [...card.querySelectorAll('a')];

      if (clickableElements.length) {
        clickableElements.forEach((el) => {
          el.addEventListener('click', (e) => e.stopPropagation());
        });
      }

      const handleClick = () => {
        const noTextSelected = !window.getSelection().toString();

        if (noTextSelected) {
          mainLink.click();
        }
      };

      card.addEventListener('click', handleClick);
    });
  }
}
